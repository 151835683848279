// vim: set ts=2 sts=2 sw=2 et:
// This file is part of OpenLifter, simple Powerlifting meet software.
// Copyright (C) 2019 The OpenPowerlifting Project.
//
// This program is free software: you can redistribute it and/or modify
// it under the terms of the GNU Affero General Public License as
// published by the Free Software Foundation, either version 3 of the
// License, or (at your option) any later version.
//
// This program is distributed in the hope that it will be useful,
// but WITHOUT ANY WARRANTY; without even the implied warranty of
// MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
// GNU Affero General Public License for more details.
//
// You should have received a copy of the GNU Affero General Public License
// along with this program.  If not, see <https://www.gnu.org/licenses/>.

import * as React from "react";
import { connect } from "react-redux";

import { GlobalState } from "../types/stateTypes";
import { Dispatch } from "redux";
import { overwriteStore } from "../actions/globalActions";

interface StateProps {
  redux: GlobalState;
}

interface DispatchProps {
  overwriteStore: (store: GlobalState) => void;
}

type Props = DispatchProps & { socketThing: any; children?: React.ReactNode };

class SocketUpdater extends React.Component<Props> {
  constructor(props: Props) {
    super(props);

    console.log("setting up listener...");
    const addListener = () => {
      if (props.socketThing.socket) {
        props.socketThing.socket.addEventListener("message", (event: any) => {
          const obj = JSON.parse(JSON.parse(event.data).item);
          Object.keys(obj).forEach((f) => {
            obj[f] = JSON.parse(obj[f]);
          });
          console.log("Message from server ", JSON.stringify(obj));
          props.overwriteStore(obj);
        });
      } else {
        console.log("don't have connection, will retry later...");
        setTimeout(addListener, 2000);
      }
    };
    addListener();
  }
  render() {
    return this.props.children;
  }
}

const mapStateToProps = (state: GlobalState): StateProps => ({
  redux: {
    ...state,
  },
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
  return {
    overwriteStore: (store) => dispatch(overwriteStore(store)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SocketUpdater);
