// vim: set ts=2 sts=2 sw=2 et:
//
// This file is part of OpenLifter, simple Powerlifting meet software.
// Copyright (C) 2020 The OpenPowerlifting Project.
//
// This program is free software: you can redistribute it and/or modify
// it under the terms of the GNU Affero General Public License as
// published by the Free Software Foundation, either version 3 of the
// License, or (at your option) any later version.
//
// This program is distributed in the hope that it will be useful,
// but WITHOUT ANY WARRANTY; without even the implied warranty of
// MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
// GNU Affero General Public License for more details.
//
// You should have received a copy of the GNU Affero General Public License
// along with this program.  If not, see <https://www.gnu.org/licenses/>.

import de from "./de.json";
import el from "./el.json";
import eo from "./eo.json";
import en from "./en.json";
import es from "./es.json";
import et from "./et.json";
import fr from "./fr.json";
import hr from "./hr.json";
import it from "./it.json";
import lt from "./lt.json";
import nl from "./nl.json";
import pt from "./pt.json";
import ru from "./ru.json";
import tr from "./tr.json";
import uk from "./uk.json";
import zh_Hans from "./zh-Hans.json";

const languages = { de, el, eo, en, es, et, fr, hr, it, lt, nl, pt, ru, tr, uk, "zh-Hans": zh_Hans };
export default languages;
