// vim: set ts=2 sts=2 sw=2 et:
//
// This file is part of OpenLifter, simple Powerlifting meet software.
// Copyright (C) 2019 The OpenPowerlifting Project.
//
// This program is free software: you can redistribute it and/or modify
// it under the terms of the GNU Affero General Public License as
// published by the Free Software Foundation, either version 3 of the
// License, or (at your option) any later version.
//
// This program is distributed in the hope that it will be useful,
// but WITHOUT ANY WARRANTY; without even the implied warranty of
// MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
// GNU Affero General Public License for more details.
//
// You should have received a copy of the GNU Affero General Public License
// along with this program.  If not, see <https://www.gnu.org/licenses/>.

import { createStore, applyMiddleware } from "redux";
import { persistStore, persistReducer } from "redux-persist";
import { composeWithDevTools } from "redux-devtools-extension";

import thunk from "redux-thunk";
import rootReducer from "./reducers/rootReducer";

import { GlobalState } from "./types/stateTypes";

function createSocket() {
  const listenKey = sessionStorage.getItem("listenKey");
  if (!listenKey) {
    return null;
  }

  const socket = new WebSocket("wss://openlifter.barbelltracker.com:8765");
  socket.addEventListener("open", () => {
    console.log("connected...", listenKey);
    socket.send(JSON.stringify({ action: "connect", listen_key: listenKey }));
  });

  return socket;
}

const socketThing = { socket: createSocket() };

setInterval(() => {
  const listenKey = sessionStorage.getItem("listenKey");
  if (listenKey) {
    socketSend(JSON.stringify({ listen_key: listenKey, action: "ping" }));
  }
}, 10000);

const socketSend = (payload: string) => {
  try {
    // https://developer.mozilla.org/en-US/docs/Web/API/WebSocket/readyState
    if (!socketThing.socket || socketThing.socket.readyState === 2 || socketThing.socket.readyState === 3) {
      console.log("socket not connected...");
      throw "socket not connected";
    }
    if (socketThing.socket.readyState === 0) {
      // still connecting, try again in a few seconds.
      setTimeout(() => {
        console.log("socket still connecting, trying again soon...");
        socketSend(payload); // next time through it should send now that the socket is open.
      }, 3000);
      return;
    }
    socketThing.socket.send(payload);
  } catch (err) {
    console.log("ERR, trying again", err);
    // reconnect and try again
    socketThing.socket = createSocket();
    setTimeout(() => {
      console.log("retrying send...");
      socketSend(payload); // next time through it should send now that the socket is open.
    }, 3000);
  }
};

// https://github.com/rt2zz/redux-persist
const persistConfig = {
  key: "root",
  storage: {
    getItem: (key: string): Promise<string> => {
      return new Promise((resolve) => {
        const listenKey = sessionStorage.getItem("listenKey");
        socketSend(JSON.stringify({ listen_key: listenKey, action: "get" }));
        resolve(localStorage.getItem(key) || "");
      });
    },
    setItem: (key: string, item: string): Promise<void> => {
      return new Promise((resolve) => {
        localStorage.setItem(key, item);

        // get the listen key from the store (if we don't have a value, then fall back to local storage)
        let listenKey = sessionStorage.getItem("listenKey");
        if (!listenKey && key === "persist:root") {
          try {
            listenKey = JSON.parse(JSON.parse(item).meet).meetId;
            if (listenKey) {
              sessionStorage.setItem("listenKey", listenKey);
            }
          } catch (err) {}
        }
        console.log("set item, listenkey", listenKey, key, JSON.parse(item));
        socketSend(
          JSON.stringify({ key: key, item: item, listen_key: listenKey, action: "save", version: Date.now() })
        );
        resolve();
      });
    },
    removeItem: (key: string): Promise<void> => {
      return new Promise((resolve) => {
        console.log("REMOVE ITEM");
        localStorage.removeItem(key);
        resolve();
      });
    },
  },
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export default function configureStore(initialState: Partial<GlobalState> = {}) {
  const store = createStore(persistedReducer, initialState, composeWithDevTools(applyMiddleware(thunk)));
  const persistor = persistStore(store as any);
  return { store, persistor, socketThing };
}
